import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';
import OL from '@rotaready/frecl/build/OL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Hickory's Customer Story - Rotaready"
          description="Since getting started with Rotaready, Hickory's have been able to better deploy 146 hours every week and achieve an ROI of 652%. Find out how..."
          url="customer-stories/hospitality-hickorys"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Hickory's restaurant from the outside"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Hickory's logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Since getting started with Rotaready, Hickory's have been able to better deploy 146 hours every week and achieve an ROI of 652%. Find out how..." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="15" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="1,545" />
              <StatSubtitle text="employees" />
            </StatItem>

            <StatItem>
              <StatHeadline text="652%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="146 hours" />
              <StatSubtitle text="better deployed every week!" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Hickory's" />
              </Content>

              <Content>
                <TextBlock text="Hickory's Smokehouse is a group of authentic barbecue style restaurants, who pride themselves on living and breathing the values of southern USA hospitality. With the very first smokehouse opening in Chester in 2010, Hickory's now has 15 restaurants and over 1,500 employees." />
              </Content>

              <Content>
                <TextBlock text="In the first few years of the business, the Hickory's team were building staff rotas using a clunky, outdated system and separately tracking attendance using pen and paper. As Hickory's grew, this approach became more and more time consuming, and the team soon recognised its inefficiency and lack of professionalism." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Efficiency, visibility and flexibility" />
              </Content>

              <Content>
                <TextBlock text="So in 2017, Hickory's set about its search for a staff scheduling solution that would eradicate these manual processes. Something that would give them a more efficient way of building staff rotas and monitoring attendance." />
              </Content>

              <Content>
                <TextBlock text="Hickory's also wanted the chosen solution to give them greater visibility over costs and a better way of communicating with one another. It also needed to achieve excellent return on investment, be easy to use and come with a good level of support - as going from pen and paper, to a tech alternative was a big jump." />
              </Content>

              <Content>
                <Blockquote
                  text="Moving from a paper based system, the user friendly nature of the chosen rota software was really really important to us! And user friendly is exactly what Rotaready was then, and still is to this day."
                  attribution="Amy Walsh, Head of Operations and Training"
                />
              </Content>

              <Content>
                <TextBlock text="After considering a number of options, Hickory's turned to Rotaready to help them take their staff scheduling to the next level. Not only could Rotaready help them with all of their requirements - it was a flexible solution tailored to hospitality businesses, making it the perfect fit for Hickory's..." />
              </Content>

              <Content>
                <Blockquote attribution="Amy Walsh, Head of Operations and Training">
                  So many <Link to='/rota-scheduling' style="color: inherit;">rota scheduling</Link> solutions are built around a 9-5 job, but Rotaready has been built specifically for hospitality businesses. And it doesn't stop there, the product has the flexibility to cater to the unique aspects of different hospitality businesses. This was a real benefit to us - all of our little ‘quirks' were taken care of. We didn't have to mould to Rotaready, it moulded to us.
                </Blockquote>
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Platter of food including chicken wings, ribs, fries and beans"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Implementation" />
              </Content>

              <Content>
                <TextBlock text="When Rotaready was implemented Hickory's had 9 restaurants, and chose to implement Rotaready in a phased, site-by-site basis, over the course of six months." />
              </Content>

              <Content>
                <Blockquote
                  text="Getting Rotaready set up was really smooth. There was a lot of joined up work and the training was excellent - the quality of training was actually one of the main reasons we went with Rotaready."
                  attribution="Amy Walsh, Head of Operations and Training"
                />
              </Content>

              <Content>
                <TextBlock text="The smokehouse has since opened six new restaurants, all of which were effortlessly set-up with Rotaready." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Benefits for the entire business" />
              </Content>

              <Content>
                <TextBlock text="Since implementing Rotaready, Hickory's has seen a great number of benefits across departments." />
              </Content>

              <Content>
                <TextBlock text="Managers can build, share and amend staff rotas in a fraction of the time it was taking them previously." />
              </Content>

              <Content>
                <TextBlock text="Employees can easily clock in and out, request time off and communicate with their manager using Rotaready's mobile app." />
              </Content>

              <Content>
                <TextBlock>
                  And all functions in head office have greater visibility and insight. A particular area of improvement came thanks to Rotaready's Cost Control, allowing the finance team to easily compare forecast against actual commercial performance. And enabling the <Link style="color:inherit;" to='/payroll'>payroll</Link> team to instantly access accurate data, all backed up by auditable records.
                </TextBlock>
              </Content>

              <Content>
                <TextBlock text="Generally, Rotaready has helped Hickory's take its business analysis to the next level..." />
              </Content>

              <Content>
                <Blockquote
                  text="There's never a time that we need a report that isn't there and I think that's of really high value to us - any analysis we need, we can get very easily."
                  attribution="Amy Walsh, Head of Operations and Training"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Five star support" />
              </Content>

              <Content>
                <TextBlock text="Whilst Hickory's have seen many benefits from using Rotaready, we all have our favourites. Here's what the team likes the most about the rota software..." />
              </Content>

              <Content>
                <TextBlock>
                  <OL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="The live chat support" />
                    <TextListItem text="How user friendly the Rota Editor is" />
                    <TextListItem text="Our account manager" />
                  </OL>
                </TextBlock>
              </Content>

              <Content>
                <Blockquote
                  text="A BIG positive of Rotaready for us is the people side - their support is genuinely 10/10! Responses are really quick, but also personable. I think I'm still surprised every time that there's a person speaking to me, and it's not just a robot. And our account manager is excellent! Nothing is ever too much trouble and he really helps us all get the most out of Rotaready."
                  attribution="Amy Walsh, Head of Operations and Training"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Next on the agenda..." />
              </Content>

              <Content>
                <TextBlock text="Hickory's customer success manager is currently working closely with the team to switch on the integration between Rotaready and Hickory's EPOS solution. By turning on this integration the Hickory's team will gain even greater visibility over their costs, have one source of truth for all their sales and labour costs and remove the need for any double entry of data." />
              </Content>

              <Content>
                <TextBlock text="Going forward the Rotaready team will continue to work closely with the smokehouse team to ensure they're leveraging all the benefits of the platform, while feeding back to the engineering team to ensure the product remains as customer- centric as possible." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Outdoor dining area of a Hickory's restaurant"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_hickorys"
                label="rotaready.com/customer-stories/hospitality-hickorys"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Hickorys.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/hickorys/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/hickorys/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 60, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/hickorys/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/hickorys/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
